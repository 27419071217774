import React, { useState, useEffect } from "react";
// import marketnamedata from './MarketNamedata';
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import chaticon from "../assets/img/chaticon.png";
import withdrawchat from "../assets/img/withdrawchat.png";
import iconbabaji from "../assets/img/logo.png";
import jQuery from "jquery";
import axios from "axios";
import logo from "../assets/img/logo.png";
import Trident from "../assets/img/trident.png";
import ReactHtmlParser from "react-html-parser";

export default function Home() {
  const initialTime = 15 * 60; // 15 minutes in seconds
  const [countdown, setCountdown] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [users, setUsers] = useState([]);
  const [users1, setUsers1] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [datamsg, setDataMsg] = useState([]);
  const [marketdetails, setmarketdetails] = useState([]);
  const [data, setData] = useState("Your data here");
  const clearData = () => {
    setData("");
  };

  useEffect(() => {
    let timer;
    if (isRunning && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isRunning, countdown]);

  const startCountdown = () => {
    setIsRunning(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const contentStyle = {
    height: "70px",
  };

  useEffect(() => {
    loaduser();
  }, []);

  const dev_id = localStorage.getItem("dev_id");
  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    let url = `${process.env.REACT_APP_API_URL_NODE}home`;

    const requestData = {
      app_id: process.env.REACT_APP_API_ID,
      user_id: user_id,
      dev_id: dev_id,
    };

    var config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(url, config);
      const data = await response.json();
      // console.warn(data.marquee_msg)
      setDataMsg(data);
      setmarketdetails(data);
      // Handle the response data as needed
      const objectRes = data;

      const objectRess = objectRes.data;
      // console.warn(objectRess)
      setUsers(objectRess);
      setUsersdata(objectRess);

      // console.warn(objectRess);

      loaduser1();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const viewBroadcast = async () => {
  //   const user_id = localStorage.getItem("userid");
  //   const apiUrl = `${process.env.REACT_APP_API_URL_NODE}view-boardcast`;
  //   const requestData = {
  //     app_id: process.env.REACT_APP_API_ID,
  //     user_id: user_id,
  //     broadcast_id: users1.id,
  //   };

  //   fetch(apiUrl, {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //   loaduser1();
  // };

  const viewBroadcast = async () => {
    try {
      const user_id = localStorage.getItem("userid");
      const apiUrl = `${process.env.REACT_APP_API_URL_NODE}view-boardcast`;
      const requestData = {
        app_id: process.env.REACT_APP_API_ID,
        user_id: user_id,
        broadcast_id: users1.id,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      console.warn(response);

      if (response.status == 200) {
        loaduser1();
      } else {
        console.error("Failed to view broadcast:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleClick = (id, name) => {
    window.location.href = `/Playgame?id=${id}&name=${name}`;
  };

  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    // alert(user_id);
    try {
      const url = `${process.env.REACT_APP_API_URL_NODE}latest-boardcast`;
      const requestData = {
        app_id: process.env.REACT_APP_API_ID,
        user_id: user_id,
      };
      var config = {
        method: "POST",
        url: url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };
      axios.post(url, requestData, config).then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers1(objectRes[0]);
        if (objectRes.length > 0) {
          jQuery("#modal-container").removeAttr("class").addClass("five");
          jQuery("body").addClass("modal-active");
        }
        // localStorage.setItem("notificationCount", objectRes.length);
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  jQuery(function () {
    jQuery("#clickme").click(function () {
      var buttonId = jQuery(this).attr("id");
      jQuery("#modal-container").removeAttr("class").removeClass(buttonId);
      jQuery("body").removeClass("modal-active");

      // jQuery(this).addClass('out');
    });
    jQuery("#closemodle").click(function () {
      var buttonId = jQuery(this).attr("id");
      jQuery("#modal-container").removeAttr("class").removeClass(buttonId);
      jQuery("body").removeClass("modal-active");

      // jQuery(this).addClass('out');
    });
  });

  return (
    <>
      <div className="popupnew" id="modal-container">
        <div className="new_five">
          <div className="modal-background position-relative">
            <div className="modal">
              <button onClick={viewBroadcast} id="closemodle">
                &times;
              </button>
              <div className="logo_babaji">
                <img src={logo} alt="" />
              </div>
              <h2 className="updateversion">{users1 && users1.title}</h2>
              <p className="updateversion_sec">
                {ReactHtmlParser(users1 && users1.message)}
              </p>
              <div className="btn-group">
                <Link
                  to="/Notification"
                  className="btnnew btn-masterful"
                  id="clickme"
                >
                  <span className="icon">&#x1F680;</span>
                  <span onClick={viewBroadcast} className="btn-txt">
                    Click me!
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="five" className="button"></div>

      <div className="mainhome">
        <div className="bg_home margin-bottom-88">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 p-0 col-4">
                <div className="widthimage">
                  <Link to="/Depositchat">
                    <img src={chaticon} className="img-fluid" />
                  </Link>
                </div>
                <div className="widthimage">
                  <Link to="/Withdrawalchat">
                    <img src={withdrawchat} className="img-fluid" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4 p-0 col-4">
                <div className="logofront">
                  <img
                    src={iconbabaji}
                    className="mx-auto d-flex justify-content-end"
                  />
                </div>
              </div>
              <div className="col-md-4 p-0 col-4">
                <div className="othergames">
                  <Link
                    to={
                      marketdetails.current_market_details &&
                      marketdetails.current_market_details.other_game
                    }
                  >
                    Other Game
                  </Link>
                </div>

                <div className="cleardata">
                  {/* <p>Data: {data}</p> */}
                  <Link to="" onClick={clearData}>
                    Clear Data
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {users && (
            <marquee className="resultmarquee" behavior="" direction="">
              {datamsg.marquee_msg}
            </marquee>
          )}
          <div className="card card-style cardbabaji">
            <div className="content">
              <center>
                <h6 className="mt-1">
                  &#128293; भरोसे का एक ही नाम &#128293;{" "}
                </h6>
                <h6> &#128591; बाबा जी खाईवाल &#128591; </h6>
              </center>
              <h6 className="d-flex justify-content-center">
                <span id="date">
                  {datamsg.nformat}
                  {/* <div>{new Date(users.nformat).toLocaleDateString()}</div>
                  <div>{new Date(users.nformat).toLocaleTimeString()}</div> */}
                  <date />
                </span>
                {/* <span id="time">14:22</span> */}
              </h6>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div class="animated-box in my-2" style={{ width: "82%" }}>
              <div className="card card-style">
                <center>
                  {marketdetails && (
                    <div className="result align-items-center">
                      <div className="px-3">
                        <img
                          src={Trident}
                          alt="Trident"
                          className="img-fluid"
                        />
                      </div>
                      <h6>
                        <p className="text-danger fs-4 mb-2">Result</p>
                        <p className="fw-bold mb-1">
                          {marketdetails.current_market_details &&
                            marketdetails.current_market_details.market_name}
                        </p>
                        <span className="fw-bold">
                          {marketdetails.current_market_details &&
                            marketdetails.current_market_details.market_result}
                        </span>
                      </h6>
                      <div className="px-3">
                        <img
                          src={Trident}
                          alt="Trident"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  )}
                </center>
              </div>
            </div>
          </div>

          <div className="card text-center clickresult animated-box">
            <p className="mb-0">
              &#128293; सबसे पहले रिजल्ट देखने के लिए क्लिक करे &#128293;
            </p>
            <Link
              to="https://www.babajiisatta.com"
              className="clicklink"
              id="neonShadow"
            >
              Click Link
            </Link>
          </div>
          <div className="card live-result">
            <p>
              <strong>Note</strong>
            </p>
            <p>{ReactHtmlParser(datamsg.note)}</p>
          </div>
          <div className="card matkalive-result">
            <p>Baba Ji Matka Live Result of {marketdetails.current_date}</p>
          </div>
          <table className="marketnametime">
            <thead>
              <tr>
                <td className="width_50">Market Name/Time</td>
                <td className="width_25">Previous Result</td>
                <td className="width_25 border-radius">Today Result</td>
              </tr>
            </thead>
          </table>
          {usersdata &&
            usersdata.map((user) =>
              user.is_open == 1 ? (
                <div
                  className="market"
                  onClick={() => {
                    handleClick(user.market_id, user.market_name);
                  }}
                >
                  {/* <div className="market"> */}
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="marketnamelist" style={{ width: "70%" }}>
                      <h3 className="animationtittle markettitlename">
                        {user.market_name}
                      </h3>

                      <ul className="liststyle">
                        <li>
                          Open Time
                          <span className="d-block">{user.openTime}</span>
                        </li>
                        <li>|</li>
                        <li>
                          Close Time
                          <span className="d-block">{user.closeTime}</span>
                        </li>
                        <li>|</li>
                        <li>
                          Result At{" "}
                          <span className="d-block">{user.resultTime}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex">
                      <div
                        className="text-center"
                        onClick={startCountdown}
                        disabled={isRunning}
                      >
                        {/* <Icon.ChevronRight /> */}
                        <h3 className="mb-0 text-white">
                          {user.market_result_previous_day}
                        </h3>
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      <div
                        className="text-center"
                        onClick={startCountdown}
                        disabled={isRunning}
                      >
                        {/* <Icon.ChevronRight /> */}
                        <h3 className="mb-0 text-white">
                          {user.market_result}
                        </h3>
                      </div>
                    </div>
                    {/* <div className="text-center playicon" onClick={startCountdown} disabled={isRunning}>
                  <Icon.ChevronRight />

                </div> */}
                  </div>
                </div>
              ) : (
                <div className="market">
                  {/* <div className="market"> */}
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="marketnamelist" style={{ width: "70%" }}>
                      <h3 className="animationtittle markettitlename">
                        {user.market_name}
                      </h3>

                      <ul className="liststyle">
                        <li>
                          Open Time
                          <span className="d-block">{user.openTime}</span>
                        </li>
                        <li>|</li>
                        <li>
                          Close Time
                          <span className="d-block">{user.closeTime}</span>
                        </li>
                        <li>|</li>
                        <li>
                          Result At{" "}
                          <span className="d-block">{user.resultTime}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex">
                      <div
                        className="text-center"
                        onClick={startCountdown}
                        disabled={isRunning}
                      >
                        {/* <Icon.ChevronRight /> */}
                        <h3 className="mb-0 text-white">
                          {user.market_result_previous_day}
                        </h3>
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      <div
                        className="text-center"
                        onClick={startCountdown}
                        disabled={isRunning}
                      >
                        {/* <Icon.ChevronRight /> */}
                        <h3 className="mb-0 text-white">
                          {user.market_result}
                        </h3>
                      </div>
                    </div>
                    {/* <div className="text-center playicon" onClick={startCountdown} disabled={isRunning}>
                  <Icon.ChevronRight />

                </div> */}
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </>
  );
}
