import React, { useEffect, useState} from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import "./Style.css";
import "./Style.scss";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Login from "./Component/Login/Login";
import Home from "./Component/Home";
import History from "./Component/Page/History";
import HistoryDeclared from "./Component/Page/History_declared";
import Wallet from "./Component/Page/Wallet";
import Play from "./Component/Page/Play";
import Help from "./Component/Page/Help";
import Jodi from "./Component/Page/Jodi";
import Manual from "./Component/Page/Manual";
import Playgame from "./Component/Page/Playgame";
import Harruf from "./Component/Page/Harruf";
// import SplashScreen from "./Component/Page/SplashScreen";
import Crossing from "./Component/Page/Crossing";
import Playcommon from "./Component/Page/Playcommon";
import Copypaste from "./Component/Page/Copypaste";
import Profile from "./Component/Page/Profile";
import Resulthistory from "./Component/Page/Resulthistory";
import Termsandcondition from "./Component/Page/Termsandcondition";
import Notification from "./Component/Page/Notification";
import Appdetails from "./Component/Page/Appdetails";
import Gameposting from "./Component/Page/Gameposting";
import Bonusreport from "./Component/Page/Bonusreport";
import Withdrawalchat from "./Component/Page/Withdrawalchat";
import Depositchat from "./Component/Page/Depositchat";
import ".././src/assets/fonts/font1/stylesheet.css";
import ".././src/assets/fonts/font-2/stylesheet.css";
import Header from ".././src/Component/Header/Header";
import Footer from ".././src/Component/Footer";
import HistoryPending from "./Component/Page/History_pending";

const RouteChangeHandler = ({ loaduser1 }) => {
  const location = useLocation(); 
  useEffect(() => {
    loaduser1(); 
  }, [location, loaduser1]); 
  return null;
};

function App() {
  const loaduser1 = async () => {
    const token = localStorage.getItem('tokenl');
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    let url = `${process.env.REACT_APP_API_URL_NODE}user-profile`;
    const requestData = {
      app_id: process.env.REACT_APP_API_ID,
      user_id: user_id,
      dev_id: dev_id,
    };

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(url, config);
      const data = await response.json();
      console.warn(token);
      console.warn(data.tokenl);
      if (token != data.tokenl) {
        localStorage.removeItem('userid');
        localStorage.removeItem('tokenl');
        localStorage.removeItem('dev_id');
        window.location.href = "/";
      } 
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
      loaduser1(); 
  }, []);

  return (

    <>
      {/* {isMobile && */}
        <BrowserRouter>
        <RouteChangeHandler loaduser1={loaduser1} />
          <Routes>
            <Route
              path="/*"
              element={
                <div>
                  <Header />
                  <Routes>
                    <Route path="/Bonus-Report" element={<Bonusreport />} />
                    <Route path="/Home" element={<Home />} />
                    <Route path="/Wallet" element={<Wallet />} />
                    <Route path="/History" element={<History />} />
                    <Route path="/History-declared" element={<HistoryDeclared />} />
                    <Route path="/pending-bet" element={<HistoryPending />} />
                    <Route path="/Play" element={<Play />} />
                    <Route path="/Playcommon" element={<Playcommon />} />
                    <Route path="/Notification" element={<Notification />} />
                    <Route path="/Help" element={<Help/>} />
                    <Route path="/Profile" element={<Profile />} />
                    <Route path="/Appdetails" element={<Appdetails />} />
                  </Routes>
                  <Footer />
                </div>
              }
            />
            <Route path="/Playgame" element={<Playgame />} />
            <Route path="/Termsandcondition" element={<><Termsandcondition /> <Header /></>}/>
            <Route path="/Resulthistory" element={<><Resulthistory /><Header /></>} />
            {/* <Route path="/Help" element={<><Help /><Header /></>} /> */}
            <Route path="/Jodi" element={<Jodi />} />
            <Route path="/Manual" element={<Manual />} />
            <Route path="/Harruf" element={<Harruf />} />
            <Route path="/Crossing" element={<Crossing />} />
            <Route path="/Copypaste" element={<Copypaste />} />
            <Route path="/Withdrawalchat" element={<Withdrawalchat />} />
            <Route path="/Depositchat" element={<Depositchat />} />
            <Route path="/Gameposting" element={<Gameposting />} />
            <Route path="/" element={<Login />} />
            {/* <Route path="/" element={<SplashScreen />} /> */}
          </Routes>
        </BrowserRouter>
       {/* }  */}
    </>
  );
}

export default App;
